import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import stackbaseReducers from '../reducers/stackbaseReducers';

import sagas from '../sagas/loginSagas';
import { StoreState } from '../types/storeTypes';
import { reducers as commonReducers, sagas as commonSagas } from '@pearlchain/component-lib-common';
import { all } from 'redux-saga/effects';
import { reducer } from 'redux-form'

const rootReducer = combineReducers<StoreState>(
    {
        stackbase: stackbaseReducers,
        common: commonReducers,
        form: reducer
    }
);

const rootSagas = function* () {
  yield all([
      sagas(),
      commonSagas()
    ])
};


const sagaMiddleware = createSagaMiddleware();
export const store = createStore<StoreState, any, any, any>(
    rootReducer,
    composeWithDevTools(applyMiddleware(
      sagaMiddleware
    ))
);

sagaMiddleware.run(rootSagas);
