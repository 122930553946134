import React from 'react';
import LoginForm from './components/LoginForm';
import { Provider } from 'react-redux';
import { store } from './store/store'
import { Router, Route, Switch } from 'react-router';
import { createHashHistory } from 'history';
import { setHistory} from '@pearlchain/component-lib-common';
import { SingleSignOn } from './components/SingleSignOn';
import RecoverPassword from './components/Recover';
import Reset from './components/Reset';

require('@pearlchain/component-lib-ui/src/styles/_pearlchain-web.scss');


export const history = createHashHistory();
setHistory(history);

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={LoginForm}/>
          <Route path="/sso" component={SingleSignOn}/>
          <Route path="/recover" component={RecoverPassword}/>
          <Route path="/reset" component={Reset}/>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
