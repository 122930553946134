import { all, call, put, takeEvery } from 'redux-saga/effects';
import { authenticate } from '@pearlchain/component-lib-common';

import ActionTypes, { LoginAction, setRealms, authError, PickRealmAction, loginSuccess } from "../actions/loginActions";
import { Realm } from '../types/loginTypes';
import { List } from 'immutable';
import { realm } from '../records/loginRecords';

interface PickRealmResponse {
    language: string,
    token: string,
    username: string
}

interface RequestLoginResponse {
    jwt: string,
    realms: Realm[]
}

export default function() {
    return all([
        takeEvery(ActionTypes.LOGIN, handleLogin),
        takeEvery(ActionTypes.PICK_REALM, handlePickRealm)
    ]);
}

function *handleLogin(action: LoginAction) {
    try {
        const { jwt, realms }: RequestLoginResponse = yield call(requestLogin, action.username, action.password);

        let storeRealms = realmsToImmutable(realms);
        yield put(setRealms(jwt, storeRealms));

    } catch (e:any) {
        yield put(authError(e.message))
    }
}

function realmsToImmutable(realms: Realm[]) {
    return List(realms.map((r) => realm({
        description: r.description,
        id: r.id,
        no: r.no,
        uniqueIdentifier: r.uniqueIdentifier
    })));
}

function requestLogin(user: string, password: string) {
    const body = JSON.stringify({ user, password });
    return fetch('auth', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body
    })
        .then((response) => {
            if(!response.ok) {
                throw new Error('Login or password incorrect');
            }

            return response.json();
        });
}

function *handlePickRealm(action: PickRealmAction) {
    try {
        const userDetails: PickRealmResponse = yield call(requestPickRealm, action.realm, action.jwt);
        authenticate(userDetails);
        yield put(loginSuccess(userDetails.token));

    } catch(e:any) {
        yield put(authError(e.message));
    }
}

function requestPickRealm(realm: string, jwt: string): Promise<PickRealmResponse> {
    return fetch('sec/api/realm', {
        method: 'post',
        headers: {
            'Authorization': `Bearing ${jwt}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        credentials: 'same-origin',
        body: realm
    })
        .then((response) => {
            if(!response.ok) {
                throw new Error('Pick realm request failed');
            }

            return response.json();
        });
}
