import React, { useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormGroup,
    Alert,
} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons'
import pearlchain_logo from '../img/pearlchain_logo.png';


library.add(faLock as any);

export default function Reset() {
    var url_string = window.location.href.replace("#","")
    var url = new URL(url_string);
    var uuid = url.searchParams.get("uuid");
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")
    const [sent, setSent] = useState(false)

    function handleSent() {
        setLoading(true)
        if(password != password2){
            setError("Passwords are not the same")
            setLoading(false)
        }
        else {
            setError("")
            const body = JSON.stringify({ uuid, newPassword:password });
            fetch('auth/reset', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                credentials: 'same-origin',
                body
            })
            .then((response) => {
                if(!response.ok) {
                    setError("An error ocurred")
                    throw new Error('Login or password incorrect');
                }
                return ""
            }).finally(() => {
                setSent(true)
                setLoading(false)
            });
        }
    }

    return (
        <div className="prl-login-page">
            <Container className="prl-login-container">
                <Card>
                    <CardImg src={pearlchain_logo} className="prl-login-img"/>
                    <CardBody className="prl-login-form">
                        {!sent ? <Form>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                        <FontAwesomeIcon icon={['fas', 'lock']}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="password" id="input-password" placeholder="New Password"
                                           value={password} onChange={(e:any) => setPassword(e.target.value)}/>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend" >
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={['fas', 'lock']}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="password" id="input-password" placeholder="Confirm Password"
                                           value={password2} onChange={(e:any) => setPassword2(e.target.value)}/>
                                    </InputGroup>
                                </FormGroup>
                                { error &&
                                <Alert color="danger">
                                    { error }
                                </Alert> }
                            <div className="flex-justify-center mt-4" >
                                <Button id="btn-signin" color="primary" type="button" onClick={handleSent}>{!loading ? "Reset":"Loading..."}</Button>
                            </div>
                        </Form> :
                        <div>
                            <h2>Your password has been resseted</h2>
                        </div>
                        }
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
