import React, { useEffect } from "react";

type EventData = {
    type: string;
    token: string | null;
}

export  function SingleSignOn(){
    const token = window.localStorage.getItem("auth-token");

    useEffect(()=>{
        const data:EventData = {type:"TOKEN_CHANGED",token:token}
        window.parent.postMessage(data,"*");
    },[token])

    return (
        <div></div>
    );
};
