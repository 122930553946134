import React, { useState } from 'react'
import {
    Button,
    Card,
    CardBody,
    CardImg,
    Container,
    Form,
    Input,
    InputGroup,
    InputGroupText,
    InputGroupAddon,
    FormGroup,
} from 'reactstrap';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'
import pearlchain_logo from '../img/pearlchain_logo.png';


library.add(faUser as any);

export default function RecoverPassword() {
    const [user, setUser] = useState("")
    const [sent, setSent] = useState(false)
    const [loading, setLoading] = useState(false)

    function handleSent() {
        setLoading(true)
        fetch('auth/recover', {
            method: 'post',
            headers: {
                'Accept': 'text/plain',
                'Content-Type': 'text/plain'
            },
            credentials: 'same-origin',
            body: user
        })
        .then((response) => {
            if(!response.ok) {
                throw new Error('Login or password incorrect');
            }
            return ""
        }).finally(() => {
            setSent(true)
            setLoading(false)
        });
    }

    return (
        <div className="prl-login-page">
            <Container className="prl-login-container">
                <Card>
                    <CardImg src={pearlchain_logo} className="prl-login-img"/>
                    <CardBody className="prl-login-form">
                        {!sent ? <Form>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <FontAwesomeIcon icon={['fas', 'user']}/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input type="text" id="input-username" placeholder="Username"
                                           value={user} onChange={(e:any) => setUser(e.target.value)}/>
                                </InputGroup>
                            </FormGroup>
                            <div className="flex-justify-center mt-4" >
                                <Button id="btn-signin" color="primary" type="button" onClick={handleSent}>{!loading ? "Recover" : "Loading..."}</Button>
                            </div>
                        </Form> : 
                            <h2>We sent you an email to reset your password</h2>
                        }
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}
