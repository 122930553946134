import { Record } from "immutable";
import { Action } from "redux";
import { LoginState } from "../types/loginTypes";
import ActionTypes, { SetRealmsAction, AuthErrorAction, LoginSuccessAction } from "../actions/loginActions";

const createState = Record<LoginState>({
    realms: undefined,
    fetching: false,
    error: undefined,
    jwt: undefined,
    success: false
});

export default function(state: Record<LoginState> = createState(), action: Action): Record<LoginState> {
    switch (action.type) {
        case ActionTypes.LOGIN:
        case ActionTypes.PICK_REALM:
            return handleBeforeRequest(state);
        case ActionTypes.RESET:
            return handleReset(state);
        case ActionTypes.SET_REALMS:
            return handleSetRealms(state, action as SetRealmsAction);
        case ActionTypes.AUTH_ERROR:
            return handleAuthError(state, action as AuthErrorAction);
        case ActionTypes.LOGIN_SUCCESS:
            return handleLoginSuccess(state, action as LoginSuccessAction);
        default:
            return state;
    }
}

function handleBeforeRequest(state: Record<LoginState>) {
    return state
        .delete('error')
        .set('fetching', true);
}

function handleReset(state: Record<LoginState>) {
    // resets the auth state, but keeps the token in the store so that
    // it can be used by other components
    const jwt = state.get('jwt');
    return createState().set('jwt', jwt);
}

function handleSetRealms(state: Record<LoginState>, action: SetRealmsAction) {
    return state
        .set('realms', action.realms)
        .set('jwt', action.jwt)
        .set('fetching', false);
}

function handleAuthError(state: Record<LoginState>, action: AuthErrorAction) {
    return state
        .set('realms', undefined)
        .set('jwt', undefined)
        .set('error', action.message)
        .set('fetching', false);
}

function handleLoginSuccess(state: Record<LoginState>, action: LoginSuccessAction) {
    return state.set('success', true).set('jwt', action.token);
}
